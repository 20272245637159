<template>
  <div class="source">
    <div class="source-title">数据源管理</div>
    <div class="source-content">
      <div class="tips">无代码快速接入</div>
      <div class="fz14">通过可视化界面配置，无需技术人员开发，只需提供数据源接口，即可实现快速接入，并支持参数智能补全，简化内部接入。</div>
      <div class="source-msg flex-start">
        <el-button size="small" type="primary" @click="addSource" v-if="add_datasource"> 添加数据源</el-button>
        <div class="col45 fz14">没有找到相对应的数据源?  请联系官方客服</div>
        <div class="col45 fz12 order" @click="showlink=true">定制开发</div>
      </div>
      <div class="flex-bet-top search-form">
        <el-button size="small" @click="delAll" v-if="del_datasource">批量删除</el-button>
        <el-form ref="form" size="small" :model="form" @submit.native.prevent>
          <el-form-item>
            <el-input v-model="form.search" @input="clearInput"  @keyup.enter.native="goSearch" placeholder="请输入搜索关键字">
            </el-input>
            <i @click="goSearch" class="el-icon-search search-btn"></i>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" v-loading="loading">
        <el-table-column type="selection" :selectable="selectable" width="55">
        </el-table-column>
        <el-table-column prop="api_name" label="数据源名称" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- <div>{{scope.row.api_type===1 ? 'API接口 ': scope.row.api_type===2 ? "名冠API接口":'数据库'}}</div> -->
            <div class="calledBack">{{ scope.row.api_name }}
              <span v-if="scope.row.sp_is_set_callback=='1'">(<a href="javascript:;">已配置回调</a>)</span>
              <span v-if="scope.row.is_install_mgnews==2||scope.row.is_install_mgtask==2">(<a href="javascript:;">绑定中</a>)</span>
              <span v-if="scope.row.is_install_mgnews==3||scope.row.is_install_mgtask==3">(<a href="javascript:;">已绑定</a>)</span>
              <span v-if="scope.row.is_install_mgnews==1||scope.row.is_install_mgtask==1">(<a href="javascript:;">已安装</a>)</span>
              <span v-else-if="scope.row.is_install_mgnews==0||scope.row.is_install_mgtask==0">(<a href="javascript:;">未安装</a>)</span>
            </div>
          </template>
          <!-- <template slot-scope="scope"> -->
          <!-- </template> -->
        </el-table-column>
        <el-table-column prop="api_type" label="数据类型" width="150">
          <template slot-scope="scope">
            <div>{{scope.row.api_type===1 ? 'API接口 ': scope.row.api_type===2 ? "名冠API接口":scope.row.api_type===3 ? '数据库': '系统接口'}}</div>
          </template>
        </el-table-column>

        <el-table-column prop="create_time" sortable label="添加时间">
        </el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.remark ? scope.row.remark :'--'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
<!--            <el-button-->
<!--                size="mini"-->
<!--                class="tab-btn"-->
<!--                type="text"-->
<!--                @click="handleCheck(scope.$index, scope.row)">查看</el-button>-->
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="edit_datasource&&scope.row.access_token=='mgtxapi_news'"
                @click="handleSetup(scope.$index, scope.row,0)">设置</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="approval_mannger&&scope.row.access_token=='mgtxapi_wxsp'"
                @click="handleSetup(scope.$index, scope.row,1)">设置</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="approval_mannger&&scope.row.access_token=='mgtxapi_task'"
                @click="handleSetup(scope.$index, scope.row,2)">设置</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="scope.row.access_token=='mgtxapi_staff_service'"
                @click="Jump()">设置</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="approval_mannger&&scope.row.access_token=='mgtxapi_wxsp'"
                @click="handleData(scope.$index, scope.row)">数据拉取</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="edit_datasource&&scope.row.api_type!=4"
                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="del_datasource&&scope.row.api_type!=4"
                :disabled="!!scope.row.is_use"
                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="scope.row.access_token=='mgtxapi_news'&& scope.row.is_install_mgnews == 1"
                @click="binding(0)">绑定</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="scope.row.access_token=='mgtxapi_task'&& scope.row.is_install_mgtask == 1"
                @click="binding(1)">绑定</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="scope.row.access_token=='mgtxapi_news'&& (scope.row.is_install_mgnews == 3||scope.row.is_install_mgnews ==2)"
                @click="binding(0)">重新绑定</el-button>
            <el-button
                size="mini"
                class="tab-btn"
                type="text"
                v-if="scope.row.access_token=='mgtxapi_task'&& (scope.row.is_install_mgtask == 3|| scope.row.is_install_mgtask == 2)"
                @click="binding(1)">重新绑定</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex-end page-cont" v-show="tableData.length">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 设置 -->
    <el-dialog
      title="设置"
      :visible.sync="dialogVisible"
      width="500px">
      <el-form
        ref="form"
        label-position="top"
        label-width="200px"
        size="small"
      >
      <div class="SpIsCallback" v-if="dialogType==1">
        <!-- <p>企业微信审批回调(建议开启)：</p> -->
        <!-- <el-popconfirm
          title="关闭企业微信审批回调事件可能会导致工作台审批数据更新不及时，请谨慎操作！"
          :hide-icon="true"
          @confirm="SpIsCallback=false"
          v-if="SpIsCallback"
        >
          <div slot="reference">
            <el-switch @change="onSpIsCallback" :value="SpIsCallback"></el-switch>
          </div>
        </el-popconfirm> -->
        <!-- <el-switch v-if="!SpIsCallback" @change="onSpIsCallback" :value="SpIsCallback"></el-switch>
        <span style="margin:0 0 0 15px;color:red;">{{ SpIsCallback?'已开启':'已关闭' }}</span> -->
      </div>
      <div v-if="dialogType==0||dialogType==2">
        <el-form-item label="应用主页配置url：">
          <el-input v-model="qwspSetting" :readonly="true" class="sySreadonly"></el-input>
        </el-form-item>
      </div>
      <div v-show="SpIsCallback" v-if="dialogType==1">
        <el-form-item label="接收消息服务器 URL：">
          <el-input v-model="qwspSetting.callback_uri" :readonly="true" class="sySreadonly"></el-input>
        </el-form-item>
        <el-form-item label="企微审批应用 secret：">
          <el-input v-model="qwspSetting.sp_secret"></el-input>
        </el-form-item>
        <el-form-item label="企微审批应用接收消息服务器 token：">
          <el-input v-model="qwspSetting.sp_token"></el-input>
        </el-form-item>
        <el-form-item label="企微审批应用接收消息服务器 EncodingAESKey：">
          <el-input v-model="qwspSetting.sp_encodingAESKey"></el-input>
        </el-form-item>
      </div>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="dialogType==1">
        <el-button size="small" style="margin:0 20px 0 0;" @click="dialogVisible = false">取 消</el-button>
        <el-popconfirm
          title="确认提交吗？"
          :hide-icon="true"
          @confirm="setQwspSetting"
        >
          <el-button size="small" type="primary" slot="reference">确 定</el-button>
        </el-popconfirm>
      </span>
    </el-dialog>

    <link-service :show.sync="showlink"></link-service>



  </div>
</template>

<script>
import {sourceList,SettingApi,qwspSettingApi,getSpIsCallback,setSpIsCallback,delApi,setinitGetNewsList,setbindTask} from "@/api/workbench";
import LinkService from "@/components/linkService";

export default {
  name: "DataManage",
  components: {LinkService},
  data(){
    return{
      form:{
        search:'',
      },
      showlink:false,
      loading:true,
      tableData:[],//表格
      pageInfo: {
        page: 1, //默认一页
        limit: 10, //默认每页十条
      },
      total:0,
      multipleSelection:[],//表格多选
      taskListTimer:'',

      dialogVisible: false, // 设置
      dialogType:0, // 状态
      SpIsCallback: true, // 设置回调状态
      qwspSetting:{}, // 企微审批参数
    }
  },
  created() {
    this.getDataList(1,10)
  },
  beforeDestroy() {
    // clearInterval(this.taskListTimer)
  },
  computed:{
    add_datasource(){
      return this.$store.state.listData.add_datasource
    },
    del_datasource(){
      return this.$store.state.listData.del_datasource
    },
    edit_datasource(){
      return this.$store.state.listData.edit_datasource
    },
    approval_mannger(){
      return this.$store.state.listData.approval_mannger
    },
  },
  watch:{
    SpIsCallback(){
      this.onSetSpIsCallback()
    }
  },
  methods:{
    // 获取企微审批参数
    getQwspSetting(){
      const that=this;
      // 获取企微审批参数
      SettingApi().then(res=>{
        if(res.code===200){
          that.qwspSetting = res.data
        }else{
          this.$message({
            message: '获取企微审批参数失败，请稍后再试',
            type: 'warning'
          })
        }
        this.loading=false
      }).catch(err=>{
        this.loading=false
      })
      // 获取企微审批对接方式
      // getSpIsCallback().then(res=>{
      //   if(res.code===200){
      //     that.SpIsCallback = res.data.sp_is_callback=='1'?true:false
      //   }else{
      //     this.$message({
      //       message: '获取企微审批对接方式失败，请稍后再试',
      //       type: 'warning'
      //     })
      //   }
      //   this.loading=false
      // }).catch(err=>{
      //   this.loading=false
      // })
    },
    Jump () {
      this.$router.push('/home/datamana/employeeSettings')
    },
    // 设置企微审批参数
    setQwspSetting(){
      const that=this;
      qwspSettingApi({
        sp_secret: that.qwspSetting.sp_secret, // 企微审批应用secret
        sp_token: that.qwspSetting.sp_token, // 企微审批应用接收消息服务器token
        sp_encodingAESKey: that.qwspSetting.sp_encodingAESKey, //企微审批应用接收消息服务器EncodingAESKey
      }).then(res=>{
        if(res.code===200){
          that.$message({
            type: 'success',
            message: '设置成功!'
          });
          that.dialogVisible = false
          that.getQwspSetting()
          that.getDataList(1,10);
        }else{
          that.$message.error('设置失败，请稍后重试');
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    // 点击开关
    onSpIsCallback(res){
      if(res){
        this.SpIsCallback = res
      }
    },
    // 设置企微审批对接方式
    onSetSpIsCallback(){
      const that=this;
      setSpIsCallback({
        sp_is_callback: that.SpIsCallback?'1':'0' // 企微审批对接方式 1 回调开启 0 回调关闭
      }).then(res=>{
      }).catch(err=>{
        console.log(err)
      })
    },
    getDataList(page,page_size,api_name){//获取数据源
      const that=this;
      sourceList({page,page_size,api_name}).then(res=>{
        if(res.code===200){
          this.tableData=res.data.data
          this.total=res.data.total
        }else{
          this.$message({
            message: '获取数据失败，请稍后再试',
            type: 'warning'
          })
        }
        this.loading=false
      }).catch(err=>{
        this.loading=false
      })
    },
    addSource(){//添加数据源
        this.$router.push('/home/datamana/dateil')
    },
    selectable(row,index){
      if (row.api_type == 2 || row.api_type == 4) {
        return false
      }
      return !row.is_use;
    },
    goSearch(){//搜索关键字
      if(this.form.search){
        if(this.form.search.length>20 || this.form.search.length<2){
          this.$message({
            message: '关键字仅限2到20个字以内',
            type: 'warning'
          })
        }else{
          this.loading=true;
            this.getDataList(1,10,this.form.search)
        }
      }else{
        this.$message({
          message: '请输入搜索关键字',
          type: 'warning'
        })
      }
    },
    clearInput(){//清空搜索返回全部
      if(!this.form.search){
        this.loading=true;
        this.getDataList(1,10)
      }
    },
    handleCurrentChange(page){
      this.getDataList(page,10,this.form.search?this.form.search:null)
    },
    handleCheck(index,row){//查看
      console.log(index,row)
    },
    // 设置
    handleSetup(index,res,type){
      this.dialogType = type // 
      if(type==1){
        this.getQwspSetting() // 获取企微审批参数
      }else if(type==0){
        this.qwspSetting = res.visit_mgnews_url
      }else if(type==2){
        this.qwspSetting = res.visit_mgtask_url
      }
      if(res.access_token == 'mgtxapi_news' || res.access_token == "mgtxapi_task"){
        if(res.is_install_mgnews == 2||res.is_install_mgtask == 2){
          this.$message('正在绑定中请稍后...');
        }else if(res.is_install_mgnews == 1||res.is_install_mgtask == 1){
          this.$confirm('请先绑定再进行设置', '绑定', {
            confirmButtonText: '马上绑定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if(res.access_token == 'mgtxapi_news'){
              this.handClickSetinitGetNewsList()
            }else{
              this.handClickSetbindTask()
            }
            //
          }).catch(() => {

          });
        }else{
          this.dialogVisible = true
        }
      }else{
        this.dialogVisible = true
      }
    },
    // 绑定新闻公告列表
    handClickSetinitGetNewsList(){
        setinitGetNewsList().then(res=>{
          if(res.code == 200){
            this.$message({
                type: 'success',
                message: res.message,
            })     
            this.startCount()      
          }else{
            this.$message({
                type: 'warning',
                message: res.message,
            })
          }
        })
    },
    // 绑定轻松任务列表
    handClickSetbindTask(){
        setbindTask().then(res=>{
          if(res.code == 200){
            this.$message({
                type: 'success',
                message: res.message,
            })     
            this.startCount()      
          }else{
            this.$message({
                type: 'warning',
                message: res.message,
            })
          }
        })
    },
    //定时开始请求
    startCount(){
         this.timer = setInterval(() => {
            this.tableData.forEach(item => {
                if((item.access_token == 'mgtxapi_news' && item.is_install_mgnews == 1) || (item.access_token == 'mgtxapi_task' && item.is_install_mgtask == 1)){
                  this.closeCount()
                }else{
                  this.getDataList(1,10);
                }
            });
        },2000)
    },
    //定时器结束请求
    closeCount(){
       clearInterval(this.timer)
    },
    // 数据拉取
    handleData(index,res){
      this.$router.push({
        path:'/home/datamana/datapull'
      })
    },
    handleEdit(index,row){//编辑
      this.$router.push({
        query:{id:row.id},
        path:'/home/datamana/dateil'
      })
    },
    destroy(id){
      const that=this;
      delApi({id:id}).then(res=>{
        if(res.code===200){
          that.$message({
            type: 'success',
            message: '删除成功!'
          });
          that.getDataList(1,10);
        }else{
          that.$message.error('删除失败，请稍后重试');
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    handleDelete(index,row){//删除
      let ids=[];
      this.$confirm('删除将无法恢复此条数据源，确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ids.push(row.id)
        this.destroy(ids)
      }).catch(() => {

      });
    },
    //新闻公告绑定
    binding(type){
      this.$confirm('是否确认绑定', '绑定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(type==0){
          this.handClickSetinitGetNewsList()
        }else{
          this.handClickSetbindTask()
        }
      }).catch(() => {

      });
    },
    handleSelectionChange(val){//多选
      this.multipleSelection = val;
    },
    delAll(){//批量删除
      let ids=[];
      const that=this;
      if(this.multipleSelection.length===0){
        this.$message({
          message: '请选择要删除的数据源',
          type: 'warning'
        })
        return
      }
      that.$confirm('删除将无法恢复所选数据源，确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.multipleSelection.map(item=>{
          ids.push(item.id)
        })
        console.log(ids)
        that.destroy(ids)
      }).catch(() => {

      });
    },
  },
}
</script>

<style>
  .el-popconfirm__main{ margin: 0 0 10px;max-width: 180px;}
  .SpIsCallback{display: flex;align-items: center;margin: 0 0 20px;}
  .sySreadonly input{background-color: #f5f7fa;border-color: #e4e7ed;color: #c0c4cc;}
</style>

<style lang="less" scoped>
.source{
    //border: 1px solid #DBDBDB;
  .source-title{
    height: 48px;
    line-height: 48px;
    background: #F8F9FA;
    padding-left: 30px;
    border-bottom: 1px solid #DBDBDB;
  }
  .source-content{
    width:84%;
    margin:45px auto;
    .page-cont{
      padding-bottom: 20px;
    }
    .tips{
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;
      color:#000;
      margin-bottom: 8px;
    }
    .source-msg{
      padding: 24px 0;

      border-bottom: 1px solid #CCCCCC;
      .col45{
        color:#004580;
        margin-left: 14px;
      }
      .order{
        width: 59px;
        height: 18px;
        cursor: pointer;
        line-height: 18px;
        text-align: center;
        border: 1px solid #004580;

      }
    }
    .search-form{
      padding-top: 20px;
      /deep/.el-form{
        width: 220px;
        position: relative;
        .search-btn{
          position: absolute;
          right:12px;
          top:11px;
          cursor: pointer;
        }
      }
    }
    .calledBack{display: flex;align-items: center;
      a{color:red;}
    }
    /deep/.el-table{
      margin-bottom: 24px;
      /deep/thead{
        /deep/th{
          padding:2px 0;
          font-weight: 400;
          color:#606266;
        }
        /deep/tr,th,td{
          background: #DFE9F5!important;
        }
      }
      .tab-btn{
        color:#004580;
      }
    }
  }
  
}
</style>